import React from 'react';
import { FixtureInfoProps } from '..';
import { SoccerScore } from './soccer';
import { TennisScore } from './tennis';

const getComponent = (sportId?: string) => {
  switch (sportId) {
    case 'sr:sport:5':
      return TennisScore;
    case 'sr:sport:1':
    case 'sr:sport:2':
    case 'sr:sport:3':
    case 'sr:sport:4':
    case 'sr:sport:12':
    case 'sr:sport:13':
    case 'sr:sport:21':
      return SoccerScore;
    default:
      return null;
  }
};

export interface MatchScoreProps extends FixtureInfoProps {
  team: 'a' | 'b';
}

export const MatchScore: React.FCC<MatchScoreProps> = (props) => {
  const Component = getComponent(props.data.sportId);

  if (!Component) return null;

  return React.createElement(Component, props);
};
